.card-data {
    >* {
        max-width: 50%;
    }

    &.no-width {
        >* {
            max-width: unset;
        }
        display: flex;
        justify-content: space-between;
        
        .card-info-data-with-tooltip {
            display: inline-flex;
            gap: 2px;
        }
    }
}

@media screen and (min-width: 1500px) {
    .card-grid-item {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 25%;
    }
}

.card-data {
    p:last-child {
        text-align: end;
    overflow-wrap: break-word;

    }
}
