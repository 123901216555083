.documentos-container{
    padding-left: 17.5px;
}
.documentos-container p{
    margin: 0;
}
.documentos-lista{
    display: flex;
    flex-direction: column;
}
.documentos-lista a{
    display: flex;
    align-items: center;
    color: var(--surface-700);
    padding-top: 10.5px;
    padding-bottom: 10.5px;
}
.documentos-lista a:hover{
    cursor: pointer;
}
.documentos-lista-icon{
    color: var(--surface-800);
}
.documentos-lista p{
    margin-left: 1rem;
}