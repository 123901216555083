.task-history-title {
  h2 {
    color: var(--text-color);
    font-family: Cairo;
    font-size: 18px;
    font-weight: 600;
  }
}

.task-change-log-table {
  // max-height: 786px;
  .p-datatable-tbody tr[role=row]:not(.p-rowgroup-header) {
    > * {
      background: var(--surface-bg)!important;
      border-bottom: 1px solid var(--surface-shadow);
      padding: 10px!important;
    }
  }
  .p-datatable-thead {
    tr[role=row] > * {
      padding: 10px!important;
    }
  }
}

div:has(.task-change-log-table) .p-datatable-scrollable .p-rowgroup-header {
    position: unset!important;
}

.p-card-body:has(.task-change-log-table) {
  padding: 21px;
}

.p-card:has(.task-change-log-table) {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
}