.p-picklist {
  .p-picklist-header {
    background-color: transparent;
    border-bottom-color: transparent;
    font-family: Cairo;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 4px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .p-picklist-filter-container {
    border-top-color: transparent;
    border-top: unset;
    padding-top: 4px;
    padding-bottom: 4px;
    padding: 4px 16px;
  }
  .p-picklist-list {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .p-picklist-list-wrapper {
    background: light-dark(#FFF, #24292F);
  }
}
