h1 {
font-size: 1.93rem;
font-style: normal;
font-weight: 700;
}

h2 {
font-size: 1.57rem;
font-style: normal;
font-weight: 500;
margin: 0;
}

.stacked-badges {
    display: flex;
    align-items: center;
    gap: 0.54rem;

    span{
        margin: 0px;
    }


    &__divider {
        width: 0.07rem;
        height: 1.22rem;
        background-color: #003E66;
    }
}
