.grid {
    .p-inputtext {
        flex: 1;
        width: 100%;
    }

    .p-dropdown {
        width: 100%;
    }
}

.col {
    >.p-multiselect {
        width: 100%;
    }
}

.p-inputtextarea {
    resize: none;
}

.responsive-column-budget-layout{
    column-count: 4;
    column-rule: 2px solid var(--clr-blue-200);
    column-gap: 40px;
    
    @media (max-width: 1030px){
        column-count: 3;
    }
}
.responsive-column-budget-layout-input-container{
    width: 100%;
    + .responsive-column-budget-layout-input-container{
        margin-top: 1rem;
    }
    .p-inputnumber{
        width: 100%;

        .p-inputtext{
            width:100%;
        }
    }
}

// Input switch
.field .p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider {
    background-color: light-dark(#CED4DA, var(--surface-highlight));
}

// General inputs
input.p-inputtext.p-component[id*="input-text-"],
input.p-inputtext,
textarea.p-inputtextarea.p-inputtext.p-component,
.p-dropdown span.p-dropdown-label,
.p-multiselect div.p-multiselect-label,
.custom-placeholder{
  &, &::placeholder, &.p-placeholder {
    color: light-dark(var(--secondary-color), var(--surface-b));
    font-family: Mulish, Arial, Helvetica, sans-serif;
  }
}
textarea.p-inputtextarea.p-inputtext.p-component{
  &, &::placeholder, &.p-placeholder {
    color: light-dark(var(--secondary-color), var(--surface-b))!important;
    font-family: Mulish, Arial, Helvetica, sans-serif;
  }
}

// Inputs switch
.p-inputswitch.success-slider .p-inputswitch-slider,
.p-inputswitch.success-slider.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--clr-success);
}
.p-inputswitch:not(.p-inputswitch-checked).success-slider .p-inputswitch-slider,
.p-inputswitch:not(.p-inputswitch-checked):hover.success-slider .p-inputswitch-slider {
  background: light-dark(#CED4DA, var(--surface-highlight)) 
}

input,
textarea{
  border-color: light-dark(#DCE1E8, #6E7B8D)!important;
  &::placeholder {
    // color: var(--text-color)!important;
    color: var(--text-color);
  }
}