.custom-brush {
  &-hide-labels {
    .recharts-brush-texts {
      display: none;
    }
  }
}

// .recharts-text {
//   * {
//     font-family: Mulish;
//     font-size: 14px;
//     font-weight: 400;
//     color: light-dark(black, white)!important;
//   }
// }
.custom-compound-chart {
  &.recharts-responsive-container {
    .custom-compound-legend-recharts {
      margin-top: 32px;
    }
    .recharts-brush {
      margin-top: 32px;
    }
  }
}

.recharts-brush {
  > rect:first-child {
    fill: var(--surface-bg);
  }
}

.recharts-pie g.recharts-layer.recharts-pie-sector  path {
  stroke: light-dark(var(--primary-color-text), var(--surface-card))!important;
}