.internal-table{
    width: 100%;
}

.internal-table .p-datatable-thead th {
    background: red;
  }

.internal-table .p-datatable-tbody tr{
    border: 1px solid var(--tabla-stroke-de-filas-colapsables, #CFCFCF);
    background: var(--gray-clr-gray-100, #F5F5F5);
}

.expandable-column{
    max-width: 4%;
}
.small-paragraph{
    color: #959595;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-tabmenu-item{
    display: flex;
    align-items: center;
    gap: 7px;
}

.tab-error-icon{
    border-radius: 4px;
    background: var(--red-1-bg, #EB0A0A);
    color: var(--red-1-txt, #F7C3C4);
    padding: 3px;
    display: flex;
    font-family: Inter;
    font-size: 12.25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 15px;
    max-height: 15px;
    align-items: center;
}

.tab-item-icon{
    border-radius: 4px;
    background: var(--blue-1-bg, #167DD7);
    color: var(--blue-1-txt, #CDECFE);
    padding: 3px;
    display: flex;
    font-family: Inter;
    font-size: 12.25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 15px;
    max-height: 15px;
    align-items: center;
}

.dropdown-table{
    display: flex;
    align-items: center;
    .p-dropdown{
        width: 100%;
    }
    span{
        width: fit-content;
    }
}

.pallet-in-table{
    color: #9E9E9E;
}

.registro-cultivo-footer{
    .p-datatable-footer{
        padding: 0;
    }
}

.subtitle-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 21px;
    max-height: 21px;
    border-radius: 4px;
    padding: 3px;
    background-color: var(--clr-brand);
    color: white;
    svg{
        width: 15px;
        height: 15px;
    }
}

.subtitle-container{
    height: fit-content;
}

.badge-requisicion-compra{
    border: 1px solid var(--clr-color);
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-width: fit-content;
    background-color: var(--clr-background);
    padding: 5px;
    color: var(--clr-color);

    &--por-atender{
        --clr-background: var(--clr-gray-300);
        --clr-color: var(--clr-gray-600);
    }

    &--por-autorizar{
        --clr-background: white;
        --clr-color: black;
    }

    &--por-cotizar{
        --clr-background: var(--clr-yellow-100);
        --clr-color: var(--clr-yellow-800);
    }

    &--en-proceso{
        --clr-background: var(--clr-green-200);
        --clr-color: var(--clr-green-400);
    }

    &--por-entregar{
        --clr-background: var(--clr-green-200);
        --clr-color: var(--clr-green-400);
    }

    &--falta-material{
        --clr-background: white;
        --clr-color: black;
    }

    &--entrega-parcial{
        --clr-background: var(--clr-purple-200);
        --clr-color: var(--clr-purple-700);
    }

    &--no-autorizado{
        --clr-background: var(--clr-red-200);
        --clr-color: var(--clr-red-700);
    }

    &--pausado{
        --clr-background: white;
        --clr-color: black;
    }

    &--cancelado{
        --clr-background: var(--clr-red-200);
        --clr-color: var(--clr-red-700);
    }

    &--finalizado{
        --clr-background: var(--clr-blue-200);
        --clr-color: var(--clr-blue-700);
    }

    &--por-atender{
        --clr-background: var(--clr-gray-300);
        --clr-color: var(--clr-gray-600);
    }
}

.item-link{
    cursor: pointer;
    color:#1C81E5;
}

.small-text{
    font-size: 11px;
    color: #9B9CA3;
}
