.range-date-calendar {
    &__select {

        width: 100%;
        max-width: 155px;
        min-width: 135px;

        .ant-select-selector {
            box-shadow: 0px 0px 7px 2px rgba(0, 191, 255, 0);

        }

        &.ctrl-selectable-range-selector .ant-select-selector:hover {
            box-shadow: 0px 0px 7px 2px rgba(0, 191, 255, 0.3);
        }
    }

    &__date-picker {
        width: 100%;
        max-width: 225px;
        min-width: 225px;
    }

    &__range-picker {
        width: fit-content;
        min-width: 225px;
    }
}
