// Tooltip
.p-tooltip-text{
  background: light-dark(#424953, var(--surface-canva))!important;
  border-radius: 3px!important;
}
.p-tooltip-text {
  background: light-dark(#535C69, var(--surface-canva)) !important;
}
.p-tooltip-top .p-tooltip-arrow {
  border-top-color: light-dark(#535C69, var(--surface-canva))!important;
  border-width: 0.3em .25em 0;
}
.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: light-dark(#535C69, var(--surface-canva))!important;
}

.p-tooltip-left .p-tooltip-arrow {
  border-left-color: light-dark(#535C69, var(--surface-canva))!important;
}

.p-tooltip {
  max-width: 250px;
}