.calidad-semanal-link > .calidad-semanal-link-icon {
  opacity: 0;
  color: var(--clr-blue-600);
}
.calidad-semanal-link:hover {
  cursor: pointer;
  .calidad-semanal-link-icon {
    opacity: 1;
  }
}

.filter-field-margin-0 > .field {
  margin: 0;
}

.peso-bajo {
  background: var(--PesoBajo-txt, #d90a1d);
}
.peso-correcto {
  background: var(--PesoCorrecto-txt, #32b20e);
}
.peso-alto {
  background: var(--PesoExcedido-txt, #e0ba09);
}
.peso-promedio {
  background: var(--blue-clr-blue-600, #45abec);
}

.comentarios-pesos-diarios {
  display: flex;
  max-height: 150px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex-shrink: 0;
  align-self: stretch;

  overflow-y: auto;
}

.borde-desviacion-pesos {
  border-left: 1px solid #dee2e6!important;
}
