.col {
  >.field {
    >.p-selectbutton {
      display: flex;

      .p-button {
        flex-basis: 100%;
      }
    }

    >.p-multiselect {
      width: 100%;
    }
  }
}
.asignacion-item {
  margin-right: 0.5rem;
}
.asignacion-filter .p-multiselect-label{
  display: flex;
}
.invernaderos-select {

  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-block: -0.18em;
    .p-badge {
      flex-shrink: 0;
    
    }
    margin-block: -0.18em;
    .p-badge {
      flex-shrink: 0;
    
    }
  }
  