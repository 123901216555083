.grid-budget-container{
    --column-count: 4;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(var(--column-count), 1fr);
     * {
        width: 100%;

    }
    &--3-columns{
        --column-count: 3;
    }
    &--2-columns{
        --column-count: 2;
    }

    &__divider{
        height: 100%;
        background-color: var(--clr-gray-200);
        width: .1rem;
        margin-left: 1rem;
    }

}