// estados de embarque
._badge_embarque {
  font-weight: 600;
  border: 1px solid;
  border-radius: 4px;
  padding: 5px;
  font-size: 10px;
}

.badge-embarque-blanco {
  @extend ._badge_embarque;
  border-color: black;
}
.badge-embarque-verde-400 {
  @extend ._badge_embarque;
  border-color: var(--green-clr-green-400, #32b20e);
  background: var(--green-clr-green-200, #e5fcd3);
  color: var(--green-clr-green-400, #32b20e);
}
.badge-embarque-verde-900 {
  @extend ._badge_embarque;
  border-color: var(--green-clr-green-900, #276017);
  background: var(--green-clr-green-100, #fafff6);
  color: var(--green-clr-green-700, #438331);
}
.badge-embarque-amarillo {
  @extend ._badge_embarque;
  border-color: var(--yellow-clr-yellow-700, #e0ba09);
  background: var(--yellow-clr-yellow-100, #fef9e0);
  color: var(--yellow-clr-yellow-800, #927907);
}
.badge-embarque-azul {
  @extend ._badge_embarque;
  border-color: var(--blue-clr-blue-700, #167dd7);
  background: var(--blue-clr-blue-200, #cdecfe);
  color: var(--blue-clr-blue-700, #167dd7);
}
.badge-embarque-on-hold {
  @extend ._badge_embarque;
  border-color: var(--gray-clr-gray-700, #3e4141);
  background: var(--gray-clr-gray-100, #f5f5f5);
  color: var(--gray-clr-gray-600, #4f5555);
}
.badge-embarque-atrasado {
  @extend ._badge_embarque;
  border-color: var(--red-clr-red-400, #eb0a0a);
  background: var(--red-clr-red-100, #ffe8ee);
  color: var(--red-clr-red-400, #eb0a0a);
}

// Detalles de embarque

.cards-detalle-embarque-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
}

.info-general-detalles-embarque-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.tabla-manifiestos-embarque {
  th,
  td {
    max-width: 200px;
    min-width: 80px;
    text-align: left;
    padding: 7px;
  }
  th {
    font-weight: 500;
    color: var(--colors-root-light-text-color-tertiary, #8a8ea6);
  }
  td {
    color: var(--colors-root-light-text-color, #44486d);
    font-variant-numeric: lining-nums tabular-nums;
    font-weight: 400;
  }
}

.mensaje-chat-embarques {
  display: flex;
  gap: 12px;

  .informacion {
    width: 150px;
    min-width: 150px;

    .nombre-usuario {
      color: black;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }

    .rol-usuario {
      color: black;
      font-size: 12.25px;
      font-style: italic;
      font-weight: 400;
    }

    .fecha-envio {
      color: #8d8d8d;
      font-family: Inter;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
    }
  }

  .mensaje {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
}

.archivos-embarque-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;
}

.archivo-embarque-item {
  display: flex;
  align-items: center;
  gap: 21px;
  align-self: stretch;

  .icono-archivo {
    color: black;
    font-size: 24px;
  }

  .nombre-archivo {
    color: #1c81e5;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  .fecha {
    color: #8d8d8d;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px; /* 154.545% */
  }

  .informacion-archivo-embarque {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
  }

  button {
    width: 38px!important;
    height: 38px;
  }
}

.subir-archivos-embarque-btn > span {
  @extend .p-button-outlined;
  @extend .p-button-rounded;
  box-shadow: none!important;
}

.label-card-embarque {
  color: var(--lara-surface-surface-600, #757575);
  font-size: 12px;
  font-weight: 400;
}

.texto-card-embarque {
  color: var(--lara-surface-surface-900, #212121);
  font-size: 15.5px;
  font-weight: 500;
  text-align: end;
}
