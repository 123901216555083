.p-inplace-content-close {
  &.p-button:enabled:hover,
  &.p-button:not(button):not(a):not(.p-disabled):hover {
    // background: var(--primary-color-500);
    // color: var(--primary-color-text);
    // border-color: var(--primary-color-500);
    background: unset;
    color: unset;
    border-color: unset;
  }
  
  &.p-button:enabled:active,
  &.p-button:not(button):not(a):not(.p-disabled):active {
    // background: var(--primary-color-600);
    // color: var(--primary-color-text);
    // border-color: var(--primary-color-600);
    background: unset;
    color: unset;
    border-color: unset;
  }
  &.p-button:focus {
    box-shadow: unset;
  }
}

.inplace-text.highlight {
  border-radius: 6px;
  border: 1px solid var(--Lara-Input-Field-inputBorder, #CED4DA);
  // background: var(--Surface-Light-mode-Highlight, #FFF);
}

// .inplace-text > div > div{
//   padding: 10.5px;
// }

.p-inplace-display {
  display: block!important;
  padding: 10px;
  // height: fit-content;
  color: light-dark(var(--secondary-color-dark), var(--text-color));
}
// Input inplace
.p-inplace-content {
  position: relative;
  button[aria-label=Close]{
    position: absolute;
    right: 10px;
    border-radius: 50%;
    background: none;
    border: none;
    padding: 0;
    margin: auto;
    width: fit-content;
    height: 100%;
    // transform: translateY(10px);
    top: 50%;
    transform: translateY(-50%);
    top: 50%;
    span.p-button-icon {
      color: var(--text-color);
      &::before{
        content: "\e90c"; // Force times circle icon
      }
    }
  }
}