// .p-orderlist {
//   &-controls {
//     display: none;
//   }
//   &-list {
//     border: unset!important;
//     height: fit-content;
//     min-height: unset;
//     max-height: unset;
//   }
//   &-item {
//     padding: 7px!important;
//   }
//   &-droppoint {
//     height: 4px!important;
//     background: #E0E0E0;
//     &:first-child,
//     &:last-child {
//       display: none;
//     }
//   }
// }
// .p-orderlist .p-orderlist-item {
//   position: relative;
//   z-index: 1;
// }

// .task-hamburger {
//   cursor: grab;
// }

// .task-hamburger:active {
//   cursor: grabbing;
// }

// .p-orderlist-droppoint {
//   background-color: transparent;
//   border: none;
// }

.today-tasks-list thead{
  display: none;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0px 6px 2px -2px var(--primary-color-200);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0px -6px 2px -2px var(--primary-color-200);
}