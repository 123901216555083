// Client chip
div.p-chip.p-component.client-project-chip,
div.p-chip.p-component.client-chip,
div.p-chip.p-component.project-chip,
div.p-chip.p-component.ocupation-chip {
  height: 22px !important;
  display: flex;
  align-items: center;
  line-height: 14px;
  background: transparent;
  padding: 0;
}

// Client badge
.client-chip,
.project-chip {
  min-width: 40px!important;
  width: fit-content !important;
  padding: 0 7px;
  justify-content: center;
}

.ocupation-chip {
  border-radius: 4px;
  justify-content: center;
  min-width: 59px!important;
  padding: 0 7px;
  width: fit-content !important;

  .bg-color {
    transition: all 0.2s;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.client-project-chip {
  min-width: 80px!important;
  border: 1px solid;
  border-radius: 16px;
  > .client,
  > .project {
    height: 100%;
  }
}

.client-project-multiselect,
.client-project-filter-multiselect {
  .p-multiselect-label {
    display: flex;
    gap: 3px;
  }

  // .project-item-template {
  //   span {
  //     white-space: break-spaces;
  //   }
  // }
}
