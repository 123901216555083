// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');


@import './theme/main';
@import './legacy/main';
@import './components/main';
@import './views/main';

// color: light-dark(var(--primary-color-highlight), #00B4FF);
.font-cairo {
  font-family: 'Cairo', sans-serif;
}

.font-mulish {
  font-family: Mulish, sans-serif;
}

// Layout
.layout-main-content {
  overflow: auto;
}

// // Input inplace
// .p-inplace-content {
//   position: relative;
//   button[aria-label=Close]{
//     position: absolute;
//     right: 10px;
//     border-radius: 50%;
//     background: none;
//     border: none;
//     padding: 0;
//     margin: auto;
//     width: fit-content;
//     height: 100%;
//     transform: translateY(10px);
//     span.p-button-icon {
//       color: var(--text-color);
//       &::before{
//         content: "\e90c"; // Force times circle icon
//       }
//     }
//   }
// }

// Error chip for new task validation
.p-chip.error-chip {
  outline: 1px solid #FC3D39!important;
  background: rgba(252, 61, 57, 0.10);
  i {
      color: #FC3D39;
  }
}

// Input time
.inplace-time .p-inplace-content-close {
  display: none;
}

.input-time input.p-inputtext.p-component.p-filled.p-inputnumber-input{
  width: 100%;
}

.input-time {
  span.p-inputnumber-button-group {
    border-radius: 0px 6px 6px 0px;
    &, button {
      background: #2896D8;
      border-color: #2896D8;
    }
  }
}

#time-register-section {
  .p-datatable .p-datatable-tbody > tr > td{
      color: var(--text-color);
      background: var(--surface-card);
      border-color: light-dark(var(--surface-300), #555E6A);
  }
}

// Tooltip
.p-tooltip-text{
  // background: light-dark(#424953, var(--surface-canva))!important;
  border-radius: 3px!important;
}

// Input switch
.field .p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider {
  background-color: light-dark(#CED4DA, var(--surface-highlight));
}

// Client chip
div.p-chip.p-component.client-chip {
  height: 22px !important;
  display: flex;
  align-items: center;
  line-height: 14px;
}

// .p-tabview {
//   .p-tabview-nav-container {
//     .p-tabview-nav-content {
//       .p-tabview-nav{
//         padding: 21px 21px 0 21px;
//         // .p-tabview-ink-bar {
//         //   margin: 21px 0;
//         // }
//       }
//     }
//   }
//   .p-tabview-panels{
//     padding: 21px;
//   }
// }
// Placeholders and inputs
// input.p-inputtext,

input.p-inputtext.p-component[id*="input-text-"],
input.p-inputtext,
textarea.p-inputtextarea.p-inputtext.p-component,
.p-dropdown span.p-dropdown-label,
.p-multiselect div.p-multiselect-label,
.custom-placeholder{
  &, &::placeholder, &.p-placeholder {
    color: light-dark(var(--secondary-color), var(--surface-b));
    font-family: Mulish, Arial, Helvetica, sans-serif;
  }
}
textarea.p-inputtextarea.p-inputtext.p-component{
  &, &::placeholder, &.p-placeholder {
    color: light-dark(var(--secondary-color), var(--surface-b))!important;
    font-family: Mulish, Arial, Helvetica, sans-serif;
  }
}
.p-multiselect-trigger-icon,
.p-dropdown-trigger-icon {
  font-size: 7px;
}

// Divider
.p-divider-solid.p-divider-horizontal:before,
.p-divider-solid.p-divider-vertical:before{
  border: unset;
  background: light-dark(var(--surface-shadow), var(--secondary-color-dark));
}

.p-divider-vertical:before {
  width: 2px;
}
.p-divider-horizontal {
  &.md:before {
    height: 2px;
  }
  &.sm:before {
    height: 1px;
  }
  &.lg:before {
    height: 3px;
  }
}

// Form labels
.grid.formgrid .grid.formgrid .field:not(:has(.p-inputswitch)) label {
  font-weight: 700 !important;
}

// Check consequences
i.pi {
  line-height: 1;
}

// Dialog form
.p-dialog-content {
  padding-bottom: 14px!important;
}
.p-dialog .p-dialog-footer {
  margin-top: 0;
}
.p-dialog-footer::before {
  min-width: 100px;
  min-height: 1px ;
  // background: var(--surface-shadow);
  background: light-dark(var(--surface-shadow), var(--secondary-color-dark));
  content: "";
  display: block;
  margin-bottom: 1rem;
}

.number-with-danger-badge{
  width: 4.5rem;
  justify-content: space-between;
}

.p-inputswitch.success-slider .p-inputswitch-slider,
.p-inputswitch.success-slider.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--clr-success);
}
.p-inputswitch:not(.p-inputswitch-checked).success-slider .p-inputswitch-slider,
.p-inputswitch:not(.p-inputswitch-checked):hover.success-slider .p-inputswitch-slider {
  background: light-dark(#CED4DA, var(--surface-highlight)) 
}

// Sidebar bold current path
.layout-menu > li.layout-root-menuitem.active-menuitem:not(:has(li)),
ul[role=menu].p-toggleable-content-enter-done > li.active-menuitem {
    font-weight: 700;
}

.p-tabview-nav li.deletable-project .pi.pi-times::before {
  content: "\e93d"; //trashcan icon
  color: var(--clr-danger);
}

.p-blockui-container:has(.p-blockui) {
  .p-inputswitch-slider,
  .p-checkbox-box.p-highlight
  {
      opacity: 0.8;
  }
}

.empty-data-teammember-message {
  * {
    color: light-dark(var(--secondary-color-dark), var(--text-color));
  }
  .message {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}

.h-shake {
  animation: h-shake 0.5s;
}

@keyframes h-shake {
0% { transform: translateX(0) }
25% { transform: translateX(3px) }
50% { transform: translateX(-3px) }
75% { transform: translateX(3px) }
100% { transform: translateX(0) }
}

// #client-form .p-card {
//   max-height: calc(100vh - 230px) !important;
//   overflow: auto !important;
//   border-radius: 6px;
// }


tr:has(.pi.pi-spin.pi-spinner) {
  opacity: 0.55;
}

.p-dropdown-empty-message{
  font-size: 0.85rem;
  color: var(--secondary-color);
}
.user-info-sidebar {
  font-size: 14px;
}
.user-name {
  font-size: 14px;
  height: 18px;
}

.user-info-sidebar .user-role {
  color: #B3E1FC!important;
  // margin-left: 31px!important;
  font-size: 12px;
  height: 18px;
}

.user-tag {
  min-width: 24px;
  min-height: 24px;
}
.user-info {
  margin: 8px auto;
}
.user-info::after {
  content: "";
  width: 100%;
  background: var(--primary-color);
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 53px;
}
.logout-caller > .pi.pi-power-off{
  width: 24px;
  text-align: center;
}
#time-register-section .p-datatable .p-datatable-tbody > tr:has(.p-datatable-cell-selected){
  & > * {
     background-color: var(--surface-bg)!important;
  }
  &:hover > *{
    background-color: light-dark(darken(#F3F7FC, 2%), darken(#33383F, 5%))!important;
  }
}

.team-member-name {
  color: var(--text-color);
}

ul.layout-menu > li.layout-root-menuitem  {
  &,
  & > a {
    min-height: 38px;
  }
}

// Aux styles for ant datepicker
// .ant-picker.ant-picker-range *::placeholder{
//   color: var(--secondary-color)!important;
// }
.ant-picker.ant-picker-range{
  border: 1px solid #ced4da!important;
  border-color: light-dark(#CED4DA, transparent)!important;
  height: 41px;
  input {
    &,
    &::placeholder {
      color: light-dark(var(--secondary-color), #F8F9FA);
    }

    background: light-dark(var(--surface-highlight), #535C69);
  }
}

.ant-picker {
  border: thin solid light-dark(#DCE1E8, #6E7B8D);
  height: 41px;
  font-family: Mulish, Arial, Helvetica, sans-serif;
  .anticon-calendar {
    color: light-dark(var(--secondary-color), white);
  }
  .ant-picker-input {
    input {
      text-transform: capitalize;

      &,
      &::placeholder {
          color: light-dark(var(--secondary-color), var(--surface-b)) !important;
        }

        background: light-dark(var(--surface-highlight), #535C69);
    }
    
    .ant-picker-suffix {
      order: -1;
      margin-left: 0;
      margin-right: 8px;

      .anticon {
        color: light-dark(var(--secondary-color), white);
      }
    }

    .ant-picker-clear {
      inset-inline-start: 0;
      inset-inline-end: unset;
    }
  }
}

.ant-picker-week-panel-row, .ant-picker-date-panel, .ant-picker-date-panel-show-week {
    .ant-picker-cell {
    // only days from previous and next month
    &:not(.ant-picker-cell-in-view, .ant-picker-cell-week) {
      opacity: 0.6;
      font-weight: 200;
    }

    &.ant-picker-cell-in-view .ant-picker-cell-inner {
      font-weight: 200;
    }
  }
}

.ant-picker-week-panel-row .ant-picker-cell-week .ant-picker-cell-inner {
  font-weight: 600;
}

.ant-picker.ant-picker-range {
  .ant-picker-input {
    width: 10ch;
  }

  .ant-picker-range-separator {
    padding-right: 6px;
    padding-left: 1px;
  }

  .ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 8px;
  }

  .ant-picker-clear {
    inset-inline-start: 11px;
    inset-inline-end: unset;
  }

  .ant-picker-active-bar {
    background: light-dark(var(--primary-color-highlight), #00B4FF);
  }
}

.ant-picker {
  background: light-dark(var(--surface-highlight), #535C69)!important;
  font-family: Mulish, Arial, Helvetica, sans-serif;

  &:hover {
    background: light-dark(var(--surface-highlight), #535C69);
    border-color: light-dark(#DCE1E8, #6E7B8D);
  }

  &:focus, &:focus-within {
    border-color: light-dark(var(--primary-color-highlight), #00B4FF);
  }

  .ant-picker-clear {
    color: #CED4DA;
  }

}

:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-range-start td.ant-picker-cell:before, 
:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-range-end td.ant-picker-cell:before, 
:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell:before, 
:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell:before.ant-picker {
  background: #0A5587;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-range-start td.ant-picker-cell::before, :where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-range-end td.ant-picker-cell::before {
  background: #00B4FF;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-picker-dropdown .ant-picker-week-panel-row-range-hover td::before {
  background: light-dark(#e6f4ff, var(--surface-bg));
}

.ant-picker-dropdown{
   .ant-picker-range-wrapper .ant-picker-panel-container, .ant-picker-panel-layout {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    background: var(--surface-highlight);
    border: 1px solid #ced4da!important;
    border-color: light-dark(#CED4DA, transparent)!important;
  }

  .ant-picker-header-view button:hover {
    color: light-dark(#1677ff, #00B4FF);
  }
}
.ant-picker-dropdown .ant-picker-content th {
  color: light-dark(var(--secondary-color), white)!important;
}
.ant-picker-dropdown * {
  color: light-dark(var(--text-color), white);
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
  background: light-dark(#e6f4ff, var(--surface-bg));
}


.ant-select {
  height: 41px;
  font-family: Mulish, Arial, Helvetica, sans-serif;

  .ant-select-selector {
      background: light-dark(var(--surface-highlight), #535C69) !important;
      border: thin solid light-dark(#DCE1E8, #6E7B8D) !important;

      .ant-select-selection-item {
        color: light-dark(var(--secondary-color), var(--surface-b)) !important;
      }
    
    .ant-picker-suffix {
      margin-left: 12px;

    }
  }

  .anticon {
    color: light-dark(var(--secondary-color), white);
  }
}

.ant-select-dropdown {
  background: var(--surface-canva);
  font-family: Mulish, Arial, Helvetica, sans-serif;
  .ant-select-item-option-content {
    color: light-dark(var(--text-color), white);
  }

  .ant-select-item:hover {
    background: var(--surface-bg) !important; 
  }

  .ant-select-item-option-selected {
    background: var(--surface-bg) !important; 
  }
}

// @media (prefers-color-scheme: dark) {
//   .ant-picker-dropdown * {
//     color: red !important; /* Aplica white en modo oscuro */
//   }
// }
.ant-picker-cell-in-range *{
  color: light-dark(var(--text-color), white)!important;
}

.ant-picker-range-arrow {
  display: none!important;
}
// Aux styles for ant datepicker
.ant-picker.ant-picker-range *::placeholder{
  color: var(--secondary-color)!important;
}
.ant-picker.ant-picker-range{
  // border: 1px solid #ced4da!important;
  height: 41px;
}

span.p-input-icon-left:has(.p-calendar){
  background: light-dark(var(--surface-canva), #535C69);
  border-radius: 6px;
  .pi-calendar {
    color: light-dark(var(--secondary-color), #F8F9FA);
  }
}


@keyframes p-progress-spinner-color {
  from,
  to {
      stroke: light-dark(#023E66, var(--clr-brand));
  }
}
.p-progress-spinner-circle {
  stroke-linecap: unset!important;
}

td.p-datepicker-today span {
  color: var(--secondary-color-dark)!important;
}

.tutorial-button svg{
  margin-right: 1px;
}

.module-item {
  z-index: 1000;
  position: relative;
}

.p-dropdown-panel.loading{
  .p-dropdown-items-wrapper{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .p-dropdown-items:after {
      content: "\e926";
      font-family: primeicons;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      animation: fa-spin 2s infinite linear;
      width: fit-content;
    }
  }

  .p-dropdown-item, .p-dropdown-empty-message {
    display: none!important;
  }
}

.p-dropdown-item-group > .flex{
  color: var(--text-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 0 1.25rem;
}

.sidebar-logo-container {
  height: 89px;
  padding: 16px 0;
  margin-bottom: 16px;
}

.custom-shadow {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
}

.theme-toggler {
  > span {
    font-size: 24px;
  }
  .switch-container {
    gap: 10px;
    .p-inputswitch .p-inputswitch-slider {
      background: light-dark(var(--primary-color-highlight), #00B4FF);
    }
  }
}

.p-tabview-nav .p-unselectable-text a {
  height: 100%;
  border: none !important;
}

.recharts-responsive-container {
  // height: 700px!important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 5s linear infinite;  /* 2 segundos, línea recta, infinito */
  transform-origin: center;
}

.center-card-body .p-card-body {
  display: flex;
  justify-content: center;
}

.month-range-picker {
  .ant-picker-input {
    width: 8ch!important;
  }
}


.tendency-chip {
  border-radius: 6px;
  width: 17.5px;
  height: 17.5px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .p-chip-icon {
    margin: 0;
    font-size: 7px;
  }
}

.ant-picker-dropdown  {
  z-index: 10000;
}

.add-budget-modal {
  .ant-picker-input {
    input {
      background: unset;
      text-transform: unset;
    }
  }
  .p-inline-message-info {
    background: rgba(0, 159, 255, 0.10);
  }
}

.average-consumption-dropdown {
  height: 31px;
  .p-dropdown-label {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.budget-movements-table {
  .p-datatable-tfoot, .p-datatable-thead {
    td, th {
      padding: 9px 7px!important;
    }
  }
}

.budget-movements-card {
  /* min-height: 0; */
  /* height: fit-content; */
  @media (max-height: 800px) {
      /* .budget-movements-table {
          height: 300px!important;
      } */
      /* min-height: fit-content!important; */
  }
}

.client-budget-layout-container {
  min-height: 0;
}
@media (max-height: 920px) {
  .client-budget-layout-container {
      min-height: unset!important;
  }
  .dashboard-card.budget-movements-card {
      min-height: fit-content!important;
      > .p-card-body {
          height: unset!important;
      }
  }
  .budget-movements-table {
      .p-datatable-wrapper {
          max-height: 400px!important;
      }
  }
}