#time-register-section {
  // General
  font-family: Cairo !important;
  height: 100%;
  h1 {
    font-size: 2.2857rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.7142rem;
    font-weight: 600;
  }

  > div:nth-child(1) {
    // margin-bottom: 10px !important;
  }

  > div:not(:first-child) {
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 10px 0px rgba(0, 0, 0, 0.03); 
    margin-bottom: 8px !important;
  }

  > div:last-child {
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 10px 0px rgba(0, 0, 0, 0.03); 
    margin-bottom: 0 !important;
  }

  // Create task
  .task-creation-wrapper {
    padding: 0 21px;
    display: flex;
    min-height: 80px;
    .p-chip {
      height: 25.5px;
      cursor: pointer;
    }
    button.p-button-success {
      width: 38px;
      height: 38px;
    }
    .input-time {
      .p-inputnumber {
        > input, 
        > span {
          height: 42px;
        }
      }
    }
  }

  // Task list main container
  .daily-tasks-wrapper {
    // height: calc(100vh - 9.5rem - 291px + 59px);
    height: calc(100vh - 9.5rem - 291px + 59px + 50px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .field:has(.today-tasks-list) {
      flex: 1;
    }
    @media (max-height: 500px) {
      height: fit-content;
      max-height: 600px;
    }

    .p-inline-message {
      background-color: transparent;
      color: light-dark(#555E6A, #f8f9fa);
    }

    > .field {
      height: calc(100% - 59px);
      margin-bottom: 0px;
      padding-bottom: 0px;
      overflow-y: auto;

      .empty-tasks-wrapper {
        height: 100%;
        align-items: center;
        display: flex;

        > .flex {
          height: 100%;
          margin: 0 !important;
          max-width: unset !important;
          width: 100%;
        }
      }
    }
  }

  // Task list
  i.p-datatable-reorderablerow-handle.pi.pi-bars {
    font-size: 23px;
  }

  // Order list
  .p-datatable .p-datatable-tbody > tr{
    background: light-dark(var(--surface-card), var(--surface-bg));
  }

  // .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role=row] > td:nth-child(2) {
  //   width: unset!important;
  //   max-width: unset!important;
  //   min-width: unset!important;
  // }

  .today-tasks-list .client-chip{
    min-width: 40px;
    min-height: unset!important;
    height: 22px !important;
    text-align: center;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .today-tasks-list td {
    padding: 0px 7px!important;
  }

  .today-tasks-list .non-reorderable {
    opacity: 0.5;
    i {
      cursor: auto !important;
    }
  }

  .task-data-chips {
    max-width: 460px;
    > .p-chip {
      &:first-child {
        height: 24.5px!important;
        min-width: 35px;
        max-width: 35px;
      }
    }
    div .p-chip {
      height: 25.5px;
    }
    .p-chip-icon.pi-comment::before {
      display: flex;
      align-items: center;
      text-align: center;
      height: 100%;
      justify-content: center;
    }
  }


    .is-multiselect {

      .p-checkbox {
        pointer-events: none;
      }

      user-select: none;
  
      tbody tr:hover {
        cursor: pointer;
  
        td {
          background-color: rgba(0, 0, 0, 0.05) !important;
        }
      }
    }
}

.task-data-chips {
  // max-width: 400px;
  // min-width: 400px;
}

.task-data {
  > span {
    max-width: 30%;
    min-width: 225px;
  }

  .task-title {
    color: light-dark(#555E6A, #f8f9fa);
    display: flex;
    flex-direction: column;
    .task-name {
      max-width: 60ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    .task-module {
      color: light-dark(#555E6A, #f8f9fa);
      font-size: 12px;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

#local-multiselect-dropdown-overlay {
    .p-multiselect-panel {
      position: static;
      .p-multiselect-header {
        .p-multiselect-close:hover {
          color: light-dark(#343a40, var(--text-color));
        }
      }
    }
    
    .inline-multiselect {
      .p-multiselect-panel {
        transition: max-height 0.32s, margin-top 0.12s, opacity 0.12s, scale 0.12s;
        opacity: 0;
        transform-origin: top;
        scale: 1 0.8;
        &.is-visible {
          opacity: 1;
          scale: 1;
        }
      }
      
      & > button {
        background: light-dark(var(--surface-canva), var(--surface-highlight));
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        padding: 0;
        align-items: center;

        &:not(.p-disabled):hover {
          border-color: var(--primary-color-200);
        }

        &.p-multiselect:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          border-color: var(--primary-color-200);
      }

        p {
          padding: 0.75rem;
          font-size: 1rem;
          font-weight: normal;
          font-family: Mulish, Arial, Helvetica, sans-serif;
          color: light-dark(var(--secondary-color), var(--surface-b));
          flex: 1 1;
          margin: 0;
          text-align: left;
        }

        .trigger-icon {
          background: transparent;
          color: light-dark(var(--text-color-secondary), #F8F9FA);
          width: 3rem;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

  }
  
    .p-dialog-footer {
      div {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
}


#root:has(#time-register-section) ~ .p-overlaypanel {
  &:before, &:after {
      display: none!important;
  }
}

.comment-modal {
  width: 454px;
  .field {
    margin-bottom: 0;
  }
}

.p-message {
  border-width: 1px!important;;
  // &-summary{
  //   display: none;
  // }
}

// Edit task
.edit-task-form {
  .field {
    input,
    textarea{
      border-color: light-dark(#DCE1E8, rgba(110, 123, 141, 80))!important;
      &::placeholder {
        // color: var(--text-color)!important;
        color: var(--text-color);
      }
    }

    .p-dropdown:not(.p-disabled).p-focus {
      border-color: #00B4FF !important;
    }

    input, .p-dropdown {
      height: 42px;
    }

    .p-dropdown-label:has(.client-chip) {
      padding-top: 0;
      padding-bottom: 0;
      margin: auto;
    }

    &.input-switch-adjust-time > div{
      margin-top: 10px;
    }
    .p-dropdown-label:has(.client-project-chip) {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}

.task-wrapper {
  min-height: 46px;

  .difference-reason-chip {
    background-color: light-dark(var(--surface-shadow), var(--surface-highlight));
    border-radius: 16px;
    padding: 5px 8px;
  }
}

.finish-day-modal {
  span.font-bold {
    color: var(--text-color);
  }
}

// Time register
.new-task-form {
  @for $i from 1 through 10 {
    > :nth-child(#{$i}) {
      order: #{$i};
    }
  }
  div:has(.module-selected) {
    order: 2;
    transform: translateX(-10px);
    white-space: nowrap;
  }
}

#time-register-section {
  .task-creation-name {
    &:has(input:focus) {
      .warning-icon-next {
        display: none;
      }
    }
    .warning-icon-next {
        background-color: transparent;
        height: 41px;

        &.opacity-100 {
          border: 1px solid #FC3D39 !important;
        }
      }

    input {
      &:not(:focus, :placeholder-shown) {
        border-color: transparent !important;
      }
      &:not(:focus) {
        background-color: transparent;
        cursor: pointer;
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .p-datatable .p-datatable-tbody > tr > td{
      color: var(--text-color);
      background: var(--surface-card);
      border-color: var(--surface-300);
  }

  .task-creation-wrapper {
    .p-chip {
      border: thin solid light-dark(transparent, rgba(110, 123, 141, 80));
      font-size: 12px;
    }
  }
  
  .tutorial-button {
    box-shadow: none;
  }
}


.total-time-this-week-icon {
  color: #00B4FF;
}

#time-register-section .today-tasks-list {
  @media screen and (max-width: 960px) {
    &.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role=row] {
      display: flex;
      justify-content: space-between;
      .task-wrapper, .task-data {
        width: 100%!important;
      }
      td {
        display: flex;
        width: unset !important;
        &:has(.task-wrapper) {
          width: 100%!important;
        }
        // flex: 1;
        // align-items: unset;
        // justify-content: unset;
      }
    }
  }
}
:root {
  --fill-drop-area-task-list: 10px;
}
.field:has(.today-tasks-list) {
  margin-top: 10px;
}
.today-tasks-list {
  .p-datatable-tbody {
    tr {
      display: flex!important;
      width: 100%;
      min-width: 100%;
  
      td {
        &:first-child {
          width: 3rem!important;
          min-height: 46px;
        }
        &:last-child {
          width: 100%!important;
        }
        display: flex;
        align-items: center;
        .task-wrapper  {
          width: 100%;
        }
      }

      &:last-child td {
        margin-bottom: var(--fill-drop-area-task-list);
        @media (max-height: 600px) {
          margin-bottom: 0;
        }
      }
      &:first-child td {
        margin-top: 14px;
      }
    }
  }
}

// target .comment-badge without a comment
.today-tasks-list .p-datatable-tbody > tr > td .comment-badge:not([class*="comment-bubble-"]) {
  border: thin solid light-dark(transparent, rgba(110, 123, 141, 80));
}

// NOTE: this is to change the color of a row on hover
.today-tasks-list tbody tr[role="row"] {

  // Previous sibling 
  &:has(+ tr:hover) { 
    
    td {
      border-bottom-color: transparent !important;
    }
  }

  // Next sibling
  &:hover + tr {
    td {
      border-top-color: transparent !important;
    }
  }

  td {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  // Hovered row styles
  &:hover {
    overflow: visible;
    position: sticky;
    z-index: 2;

    td {
      border-color: transparent !important;
      border-top: 1px solid light-dark(#67B8E9, #023E66) !important;
      border-bottom: 1px solid light-dark(#67B8E9, #023E66) !important;
      // box-shadow: 0 1px 0 light-dark(#67B8E9, #023E66) inset, 0px -1px 0 light-dark(#67B8E9, #023E66) inset;
      background: light-dark(#cdecfe, #0A5587) !important;

      .difference-reason-chip {
        background: light-dark(#cdecfe, #0A5587);
      }
    }
    
    .task-data-chips {
      > div:nth-child(1) {
        background-color: #a4aab2;
      }
      
      > div:nth-child(2) .p-chip {
        background: light-dark(#2896d8, var(--clr-info));
        color: white;
      }
    }
  }
}
.daily-tasks-wrapper .field.col-12.pb-0 {
  margin-bottom: 0;
}
.dropdown-module-filter-inplace.disabled {
  pointer-events: none;
  & > .p-chip {
    opacity: 0.5;
  }
} 


.register-task-dropdown-panel.p-dropdown-panel {
  max-width: 240px !important;

  .p-dropdown-items .p-dropdown-item-group {
    padding: 4px 10px;
    & > div {
      display: flex;
      gap: 7px;
      align-items: center;
      font-size: 14px;

      .pi, .icon {
        color: var(--text-color);
        width: 18px;
        height: 18px;
      }
    }
    
    .pi {
      display: flex;
      align-items: center;
    }
  }

  .p-dropdown-items-wrapper {
    max-height: 240px !important;

    .p-dropdown-item {
      padding: 8px 10px;

      .category-item{
        font-size: 12px;
        span:nth-child(1) {
          font-weight: 700;
        }

        span:nth-child(2) {
          font-weight: 400;
        }
      }
    }
  }
}