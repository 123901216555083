.auto-layout {
  h1 {
    font-family: Cairo;
  }
 
  .mb-5 {
    display: flex;
    flex-direction: column;
  }  

  .p-checkbox-box.p-highlight {
    --primary-color-200: #2896D8;
  }

  .text-blue-aika {
    color: light-dark(var(--text-color), #F8F9FA);
  }

  .p-button {
    --clr-brand: #006AA6;
  }
}
