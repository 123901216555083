.driver-popover {
  // background: var(--surface-bg);
  background: light-dark(var(--surface-highlight), var(--surface-bg));
  color: var(--text-color);
}

.driver-popover-arrow-side-left {
  border-left-color: var(--surface-bg);
}
.driver-popover-arrow-side-right {
  border-right-color: var(--surface-bg);
}
.driver-popover-arrow-side-top {
  border-top-color: var(--surface-bg);
}
.driver-popover-arrow-side-bottom {
  border-bottom-color: var(--surface-bg);
}
#driver-popover-content *:not(.driver-popover-title){
  font-family: Mulish;
}

#driver-popover-title {
  font-family: 'Cairo';
}

#start-tutorial-btn{
  position: absolute;
  top: 10px;
  right: 180px;
  z-index: 10000;
}

.driver-popover-prev-btn {
  background-color: transparent!important;
  border: unset;
  border-color: transparent!important;
  color: var(--text-color)!important;
  text-shadow: none !important;
  border-radius: 1.5rem!important;
}
.driver-popover-next-btn {
  background-color: var(--primary-color)!important;
  border: 1px solid var(--primary-color-200)!important;;
  color: var(--primary-color-text)!important;
  text-shadow: none !important;
  border-radius: 1.5rem!important;
}

.tutorial-button {
  font-size: 24px;
  padding: 0;
  width: 41px;
}

.driver-popover:not(.first-step) .driver-popover-prev-btn {
  display: none !important;
}

.driver-popover-btn-disabled {
  display: none!important;
}