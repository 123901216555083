.asistencia-row{
    display: flex;
    gap: .4rem;

    .asistencia-item{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--clr-gray-red-100);
        color: var(--clr-gray-900);
        height: 1.3rem;
        width: 1.3rem;
        font-size: .7rem;
        border-radius: 2px;
        box-shadow: 0 0 2px rgba(14, 14, 14, 0.521) ;
    
        &--falta{
            background-color: var(--clr-red-800);
            color: var(--clr-gray-red-100);
            
        }
        &--permiso{
            background-color: var(--clr-green-900);
            color: var(--clr-gray-red-100);
            
        }
        &--vacaciones{
            background-color: var(--clr-blue-700);
            color: var(--clr-gray-green-100);


        }
       
    }

}

